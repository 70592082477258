import {Injectable} from '@angular/core';
import {HttpClient, HttpContext} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {AttributesModel, Data} from '../../../shared/models/data.model';
import {Faq, FaqCategory} from '../../../shared/models/faqs.model';
import {IS_STRAPI_API} from '../../../core/enums/public-api.token';

@Injectable({
    providedIn: 'root'
})
export class FaqSectionService {

    private readonly ENDPOINT_STRAPI: string = `${environment.addressPressupStrapi}`;

    constructor(private http: HttpClient) { }

    public getFAQ(): Observable<Data<AttributesModel<Faq>>> {
        return this.http.get<Data<AttributesModel<Faq>>>(`${this.ENDPOINT_STRAPI}/api/faqs?populate=*`,
            {
                context: new HttpContext().set(IS_STRAPI_API, true),
            });
    }

    public getFAQCategories(): Observable<Data<AttributesModel<FaqCategory>>> {
        return this.http.get<Data<AttributesModel<FaqCategory>>>(`${this.ENDPOINT_STRAPI}/api/faq-categories?populate=*`,
            {
                context: new HttpContext().set(IS_STRAPI_API, true),
            });
    }
}
