<div class="modal-header border-0">
    <div class="icon-box">
        <img [src]="icon" class="material-icons" alt="svg icon"/>
    </div>
</div>
<div class="modal-body">
    <h3 class="text-dark text-uppercase fw-bold text-align-center">
        {{ title | translate }}
    </h3>
    <p class="text-modals-font-18px text-align-center">
        {{ subTitle | translate }}
    </p>
</div>
<div class="modal-footer d-block text-center bg-footer">
    <a [tabIndex]="0"></a>
    <button *ngIf="visibleCancelBtn" class="btn btn-outline me-3 mb-3 default-dim-modals-button" (click)="cancelAction()">
        {{ textCancelBtn | translate }}
    </button>
    <button class="btn btn-solid mb-3 default-dim-modals-button" (click)="successAction()">
        {{ textConfirm | translate }}
    </button>
</div>
