import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    standalone: true,
    name: 'euroFormat'
})
export class EuroFormatPipe implements PipeTransform {

    transform(value: string | undefined): string {
        if (!value) {
            return '';
        }
        return value.replace('€', '€ ');
    }
}
