<div class="icon-nav">
    <ul class="d-flex align-items-center">
        <li class="show-div setting mobile-profile">
            <app-icon-profile-user></app-icon-profile-user>
        </li>
        <li class="onhover-div mobile-lang">
            <div>
                <app-language-selector></app-language-selector>
            </div>
        </li>
        <li class="onhover-div mobile-cart">
            <div>
                <div [routerLink]="['/shop/cart']" class="d-flex">
                    <img src="assets/images/icon/menu/icn_cestino.svg" class="img-fluid dim-icon" alt="">
                    <i class="ti-shopping-cart"></i>
                </div>
            </div>

            <span class="cart_qty_cls">{{ products.length }}</span>
            <ul class="show-div shopping-cart" *ngIf='!products.length'>
                <h5>{{ 'CART.EMPTY_CART' | translate }}</h5>
            </ul>
            <ul class="show-div shopping-cart box-shadow" *ngIf='products.length'>
                <li *ngFor="let product of products; let  i = $index">
                    <div class="d-flex">
                        <div class="align-content-center" style="background-color: #f8f8f8;">
                            <img *ngIf="product?.itemStructure?.preview_id_picture?.medium"
                                 ngSrc="{{product?.itemStructure?.preview_id_picture?.medium}}" placeholder
                                 class="img-fluid me-2" width="80" height="130"
                                 alt="{{product?.itemStructure?.preview_id_picture?.originalname}}" loading="lazy"
                                 fetchpriority="auto" style="width: 60px">
                        </div>
                        <div class="media-body ms-1 p-1" style="width: 65% !important;">
                            <a>
                                <span class="d-flex fw-600 text-dark mb-1">{{ product?.itemStructure?.preview_title_it }}</span>
                            </a>
                            <span class="mb-0 text-p">({{ product?.quantity }}pz.) {{ product?.total |  currency: 'EUR': 'symbol': '1.2-2' | euroFormat }}
                                di cui IVA  {{ calculateIva(product) |  currency: 'EUR': 'symbol': '1.2-2' | euroFormat}}</span>
                        </div>
                    </div>
                    <div class="close-circle">
                        <button type="button" class="close-btn" style="padding: 0;border: none;background: none;">
                            <span aria-hidden="true" style="font-size: large; color: gray;"
                                  (click)="deleteItem(i)">×</span>
                        </button>
                    </div>
                </li>
                <li>
                    <div class="total">
                        <div class="justify-content-between d-flex text-p">{{ 'CART.TOTAL_PRICE' | translate }} : <span
                                class="text-red-theme fw-600">{{ total | async }} €</span></div>
                        <div class="justify-content-between d-flex text-p">{{ 'CART.WHICH_VAT' | translate }} : <span>{{ vatTotal | async }}
                            €</span></div>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a style="font-size: unset" [routerLink]="['/shop/cart']" class="view-cart"><span>Vai al carrello</span></a>
                        <a (click)="toCheckout()" class="checkout clickable"><span>checkout</span></a>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>
