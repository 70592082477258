import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ItemBreadcrumb} from '../../models/item-breadcrumb.model';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {tap} from 'rxjs';
import {TabSelectedCode} from '../../../modules/profile/interfaces/TabSelectedCode';
import {
    getItemsAccountData,
    getItemsAssistance,
    getItemsBillingAddressCreation, getItemsBillingAddressModify,
    getItemsCashback,
    getItemsCreateAssistance,
    getItemsNotificationManagement,
    getItemsShippingAddress, getItemsShippingAddressCreation, getItemsShippingAddressModify, getItemsUpdatePassword
} from '../../../core/helpers/breadcrumb-items';
import {isEmpty} from 'lodash';
import {BaseComponent} from '../../../core';
import {TranslateService} from '@ngx-translate/core';

enum Route {
    BILLING_ADDRESS_CREATE = '/profile/billing-data/management-billing-address/create',
    BILLING_ADDRESS_MODIFY = '/profile/billing-data/management-billing-address/modify',
    SHIPPING_ADDRESS_MODIFY = '/profile/shipping-data/management-shipping-address/create',
    SHIPPING_ADDRESS_CREATE = '/profile/shipping-data/management-shipping-address/create',

}

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() title: string;
    @Input() titleDynamic: any;
    @Input() itemsBreadcrumb: Array<ItemBreadcrumb>;

    private mappingRoutes: Map<string, Array<ItemBreadcrumb>> = new Map<string, Array<ItemBreadcrumb>>;
    private currentInputBreadcrumb: Array<ItemBreadcrumb>;

    constructor(
        public translateService: TranslateService,
        private router: Router
    ) {
        super(translateService);
    }

    ngOnInit(): void {
        this.mappingRoutes.set(`/${TabSelectedCode.ACCOUNT_DATA.route}`, getItemsAccountData());
        this.mappingRoutes.set(`/${TabSelectedCode.SHIPPING_ADDRESSES.route}`, getItemsShippingAddress());
        this.mappingRoutes.set(`/${TabSelectedCode.ORDERS.route}`, []);
        this.mappingRoutes.set(`/${TabSelectedCode.CASHBACK.route}`, getItemsCashback());
        this.mappingRoutes.set(`/${TabSelectedCode.NOTIFICATION.route}`, getItemsNotificationManagement());
        this.mappingRoutes.set(`/${TabSelectedCode.ASSISTANCE.route}`, getItemsAssistance());
        this.mappingRoutes.set(`/${TabSelectedCode.UPDATE_PASSWORD.route}`, getItemsUpdatePassword());
        this.mappingRoutes.set('/profile/assistance/new-report', getItemsCreateAssistance());
        this.mappingRoutes.set(Route.BILLING_ADDRESS_CREATE, getItemsBillingAddressCreation());
        this.mappingRoutes.set(Route.BILLING_ADDRESS_MODIFY, getItemsBillingAddressModify());
        this.mappingRoutes.set(Route.SHIPPING_ADDRESS_CREATE, getItemsShippingAddressCreation());
        this.mappingRoutes.set(Route.SHIPPING_ADDRESS_MODIFY, getItemsShippingAddressModify());
        this.getItemsBreadcrumbByURL(this.router.url);
        this.router.events?.pipe(
            filter((event) => event instanceof NavigationEnd),
            tap((value: NavigationEnd) => this.getItemsBreadcrumbByURL(value?.url))
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.title = changes?.title?.currentValue ?? this.title;
        this.titleDynamic = changes?.titleDynamic?.currentValue ?? this.titleDynamic;
        this.itemsBreadcrumb = changes?.itemsBreadcrumb?.currentValue ?? this.itemsBreadcrumb;
        this.currentInputBreadcrumb = changes?.itemsBreadcrumb?.currentValue;
    }

    public goToPageSelect(path: string): void {
        if (path !== '') {
            this.router.navigate([`${path}`]);
        }
    }

    private getItemsBreadcrumbByURL(url: string): void {
        if (this.currentInputBreadcrumb) {
            this.currentInputBreadcrumb = undefined;
            return;
        }
        const targetItems = this.mappingRoutes.get(url);
        if (isEmpty(targetItems)) {
            this.itemsBreadcrumb = [];
        } else {
            this.itemsBreadcrumb = targetItems;
        }
    }
}
