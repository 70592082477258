export const environment = {
    production: true,
    mockResponse: true,
    stripe_token: 'STRIPE_TOKEN',
    addressPressupAPI: 'https://pressup-api.azurewebsites.net/',
    addressPressupAPIDEM: 'https://pressup-api-dem.azurewebsites.net/api/',
    addressPressupBackofficeAPI: 'https://pressup-api-bo-staging.democom.tech/api/',
    addressPressupBackoffice: 'https://pressup-api-bo-staging.democom.tech/',
    addressPressupStrapi: 'https://pressupblog-staging.democom.tech',
    api_v1: 'v1',
    api_v2: 'v2',
    domain: 'https://pressupstaging.democom.tech/',
    photonApi: 'https://photon.komoot.io/api/',
    jwtStrapiPressup: '04e54a17eed8bf1a4df1db9be1db30c0bed27b7d5f9185c1f3ae36ccb4ede43ed815c00d8a11f5c1adbabb6372087d53d5b6341f635e9efe4afc12808a65a06c5023eabd499d6eaddc02bbec4edbcf9f3835a88ed899859afaff249da412fa86eb50121e56ffb3c6a85f26f7fef22ac425ba20a1d02f41f7a17992eab8601116',
    xApiKey: 'DBjkmVXr+LRQsE4z9nUnricKT11lfAenjYLlg+UsSvc='
};
