import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {PagesFacade} from '../../../pages/pages.facade';
import {ProcessingOrderCart} from '../../../shop/cart/models/processing-order-cart';
import {map} from 'rxjs/operators';
import {ItemStructure} from '../../dynamic-loader/models/item-structure.model';
import {RedirectModalComponent} from '../../modals/redirect-modal/redirect-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public products: ProcessingOrderCart[] = [];
    public search = false;
    public subscriptions: Array<Subscription> = [];


    constructor(public pagesFacade: PagesFacade,
                private cdr: ChangeDetectorRef,
                private modalService: NgbModal,
                private router: Router) {
    }

    ngOnInit(): void {
        this.getProduct();
    }

    public get total(): Observable<number> {
        return of(this.products?.reduce((sum, item) => {
            const totalNumber = parseFloat(item.total.replace(',', '.'));
            return sum + totalNumber;
        }, 0));
    }

    public calculateIva(product: ProcessingOrderCart): number {
        const total = parseFloat(product.total.replace(',', '.'));
        const iva = (total * 22) / 100;
        return parseFloat(((iva * 100) / 100).toFixed(2));
    }

    public get vatTotal(): Observable<number> {
        return of(this.products?.reduce((sum, item) => {
            const totalNumber = parseFloat(item.total.replace(',', '.'));
            const vatAmount = totalNumber * 0.22;
            return  parseFloat((sum + vatAmount).toFixed(2));
        }, 0));
    }

    public getProduct(): void {
        this.pagesFacade.getCartItems$().pipe(
            switchMap(products => {
                this.products = products;
                const apiCalls = products.map(product =>
                    this.getItemStructureById(product.id).pipe(
                        map(itemStructure => (
                            {
                                ...product,
                                expanded: product.expanded ?? false,
                                itemStructure
                            }))
                    )
                );
                return forkJoin(apiCalls);
            }),
            tap((uploadedProduct: Array<ProcessingOrderCart>) => {
                this.products = uploadedProduct;
                this.cdr.detectChanges();
            })
        ).subscribe();
    }

    public getItemStructureById(id: string): Observable<ItemStructure> {
        return this.pagesFacade.getItemStructureById(id);
    }

    public toCheckout() {
        const sb = this.pagesFacade.getUser$().pipe(
            tap(user => {
                if (user && !user?.userinfo) {
                    const modalRef = this.modalService.open(RedirectModalComponent, {
                        centered: true,
                        backdrop: 'static',
                        windowClass: 'commandsModal'
                    });
                    modalRef.componentInstance.icon = 'assets/images/press-up/icone-cerchio/icn_modale_user.svg';
                    modalRef.componentInstance.subTitle = 'MODAL.COMPLETE_ACCOUNT';
                    modalRef.componentInstance.visibleCancelBtn = true;
                    this.successModal(modalRef);
                } else {
                    if (user) {
                        this.router.navigate(['/shop/cart/shipping-type']);
                    }
                }
            })).subscribe();
        this.subscriptions.push(sb);
    }

    public deleteItem(i: number) {
        this.products.splice(i, 1);
        this.pagesFacade.setCartItems$(this.products);
    }

    private successModal(modalRef: NgbModalRef) {
        const sb = modalRef.closed.pipe(tap(() => this.router.navigateByUrl('/profile/account-data'))).subscribe();
        this.subscriptions.push(sb);
    }
}
