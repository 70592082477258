import {Component} from '@angular/core';
import {NgIf} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-redirect-modal',
    standalone: true,
    imports: [
        NgIf,
        TranslateModule
    ],
    templateUrl: './redirect-modal.component.html',
    styleUrl: './redirect-modal.component.scss'
})
export class RedirectModalComponent {
    title: string;
    subTitle: string;
    textConfirm = 'GENERAL.CONFIRM';
    textCancelBtn = 'GENERAL.CANCEL';
    visibleCancelBtn: boolean;
    icon: string;

    constructor(public translateService: TranslateService,
                public activeModal: NgbActiveModal) {
    }

    public successAction(): void {
        this.activeModal.close();
    }

    public cancelAction(): void {
        this.activeModal.dismiss();
    }
}
