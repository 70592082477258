import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BarRatingModule} from 'ngx-bar-rating';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {TranslateModule} from '@ngx-translate/core';

// Header and Footer Components
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header/header.component';

// Components
import {LeftMenuComponent} from './components/left-menu/left-menu.component';
import {MenuComponent} from './components/menu/menu.component';
import {SettingsComponent} from './components/settings/settings.component';
import {CategoriesComponent} from './components/categories/categories.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {ProductBoxOneComponent} from './components/product/product-box-one/product-box-one.component';
import {ProductBoxTwoComponent} from './components/product/product-box-two/product-box-two.component';
import {ProductBoxThreeComponent} from './components/product/product-box-three/product-box-three.component';
import {ProductBoxFourComponent} from './components/product/product-box-four/product-box-four.component';
import {ProductBoxFiveComponent} from './components/product/product-box-five/product-box-five.component';
import {ProductBoxVerticalComponent} from './components/product/product-box-vertical/product-box-vertical.component';
import {
    ProductBoxVerticalSliderComponent
} from './components/product/product-box-vertical-slider/product-box-vertical-slider.component';

// Modals Components
import {NewsletterComponent} from './components/modal/newsletter/newsletter.component';
import {QuickViewComponent} from './components/modal/quick-view/quick-view.component';
import {CartModalComponent} from './components/modal/cart-modal/cart-modal.component';
import {CartVariationComponent} from './components/modal/cart-variation/cart-variation.component';
import {VideoModalComponent} from './components/modal/video-modal/video-modal.component';
import {SizeModalComponent} from './components/modal/size-modal/size-modal.component';
import {AgeVerificationComponent} from './components/modal/age-verification/age-verification.component';

// Skeleton Loader Components
import {SkeletonProductBoxComponent} from './components/skeleton/skeleton-product-box/skeleton-product-box.component';

// Layout Box
import {LayoutBoxComponent} from './components/layout-box/layout-box.component';

// Tap To Top
import {TapToTopComponent} from './components/tap-to-top/tap-to-top.component';

// Pipes
import {DiscountPipe} from './pipes/discount.pipe';
import {WhatsappContactComponent} from './components/whatsapp-contact/whatsapp-contact.component';
import {FeedbackModalComponent} from './components/feedback-modal/feedback-modal.component';
import {LanguageSelectorComponent} from './components/language-selector/language-selector.component';
import {IconProfileUserComponent} from './components/icon-profile-user/icon-profile-user.component';
import {GetInitialsDirective} from '../core';
import {NgSelectCustomComponent} from './components/ng-select-custom/ng-select-custom.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {InvoiceComponent} from './components/invoice/invoice.component';
import {PageInWorkTemplateComponent} from './components/page-in-work-template/page-in-work-template.component';
import {TableModule} from 'primeng/table';
import {CustomCardComponent} from './components/custom-card/custom-card.component';
import {ProductCardThreeDimensionalComponent} from './components/product-card-three-dimensional/product-card-three-dimensional.component';
import {BannerLinkComponent} from './components/banner-link/banner-link.component';
import {CustomChipComponent} from './components/custom-chip/custom-chip.component';
import {TrustboxProductReviewsComponent} from './components/trustbox-product-reviews/trustbox-product-reviews.component';
import {SkeletonBuilderComponent} from './components/skeleton-builder/skeleton-builder.component';
import {SkeletonModule} from 'primeng/skeleton';
import {AddressAutocompleteComponent} from './components/address-autocomplete/address-autocomplete.component';
import {DeleteItemModalComponent} from './modals/delete-item-modal/delete-item-modal.component';
import {MatIcon} from '@angular/material/icon';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {TooltipModule} from 'primeng/tooltip';
import {ShowIfTruncatedDirective} from '../core/directives/ShowIfTruncatedDirective';
import {
    ShowIfTruncateVerticalDirective
} from '../core/directives/show-if-truncate-vertical.directive';
import {
    NgSelectCustomAutocompleteComponent
} from './components/ng-select-custom-autocomplete/ng-select-custom-autocomplete.component';
import {EuroFormatPipe} from "../core/pipes/EuroFormatPipe";

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        LeftMenuComponent,
        MenuComponent,
        SettingsComponent,
        BreadcrumbComponent,
        CategoriesComponent,
        ProductBoxOneComponent,
        ProductBoxTwoComponent,
        ProductBoxThreeComponent,
        ProductBoxFourComponent,
        ProductBoxFiveComponent,
        ProductBoxVerticalComponent,
        ProductBoxVerticalSliderComponent,
        NewsletterComponent,
        QuickViewComponent,
        CartModalComponent,
        CartVariationComponent,
        VideoModalComponent,
        SizeModalComponent,
        AgeVerificationComponent,
        SkeletonProductBoxComponent,
        LayoutBoxComponent,
        TapToTopComponent,
        DiscountPipe,
        WhatsappContactComponent,
        FeedbackModalComponent,
        LanguageSelectorComponent,
        IconProfileUserComponent,
        GetInitialsDirective,
        NgSelectCustomComponent,
        InvoiceComponent,
        PageInWorkTemplateComponent,
        CustomCardComponent,
        ProductCardThreeDimensionalComponent,
        BannerLinkComponent,
        CustomChipComponent,
        TrustboxProductReviewsComponent,
        SkeletonBuilderComponent,
        AddressAutocompleteComponent,
        DeleteItemModalComponent,
        ShowIfTruncatedDirective,
        NgSelectCustomAutocompleteComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        BarRatingModule,
        LazyLoadImageModule,
        NgxSkeletonLoaderModule,
        TranslateModule,
        NgOptimizedImage,
        NgSelectModule,
        MatTooltipModule,
        TableModule,
        SkeletonModule,
        MatIcon,
        InlineSVGModule,
        TooltipModule,
        ShowIfTruncateVerticalDirective,
        EuroFormatPipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        BarRatingModule,
        LazyLoadImageModule,
        NgxSkeletonLoaderModule,
        TranslateModule,
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        CategoriesComponent,
        ProductBoxOneComponent,
        ProductBoxTwoComponent,
        ProductBoxThreeComponent,
        ProductBoxFourComponent,
        ProductBoxFiveComponent,
        ProductBoxVerticalComponent,
        ProductBoxVerticalSliderComponent,
        NewsletterComponent,
        QuickViewComponent,
        CartModalComponent,
        CartVariationComponent,
        VideoModalComponent,
        SizeModalComponent,
        AgeVerificationComponent,
        SkeletonProductBoxComponent,
        LayoutBoxComponent,
        TapToTopComponent,
        DiscountPipe,
        WhatsappContactComponent,
        FeedbackModalComponent,
        GetInitialsDirective,
        NgSelectCustomComponent,
        PageInWorkTemplateComponent,
        CustomCardComponent,
        ProductCardThreeDimensionalComponent,
        BannerLinkComponent,
        CustomChipComponent,
        TrustboxProductReviewsComponent,
        SkeletonBuilderComponent,
        AddressAutocompleteComponent,
        ShowIfTruncatedDirective,
        ShowIfTruncateVerticalDirective,
        NgSelectCustomAutocompleteComponent
    ]
})
export class SharedModule {
}
