<div id="cart_side" class="add_to_cart" [ngClass]="direction" [class.openSide]="productService?.OpenCart">
    <a href="javascript:void(0)" class="overlay" (click)="closeCart()"></a>
    <div class="cart-inner">
        <div class="cart_top">
            <h3>my cart</h3>
            <div class="close-cart" (click)="closeCart()">
                <a href="javascript:void(0)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="row" *ngIf="products.length">
            <div class="col-12">
                <div class="mt-0">
                    <p-table [value]="products" [paginator]="false">
                        <ng-template pTemplate="header">
                            <tr>
                                <th *ngFor="let header of " style="width: auto;">
                                    {{ header | translate }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product>
                            <tr>
                                <td>{{ product?.createdAt | date: 'dd.MM.YYYY'}}</td>
                                <td>{{ product?.orderItemId }}</td>
                                <td>
                                    {{product?.status?.description}}
                                </td>
                                <td>
                                    {{product?.description}}
                                </td>
                                <td>
                                    <div class="bottom">
                                        <div>
                                            <i class="pi pi-angle-right mt-3 clickable color-default"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <ul class="cart_product">
                        <li *ngFor="let product of products">
                            <div class="media">
                                <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                                    <img class="me-3" [src]="product.images[0].src" [alt]="product.images[0].alt">
                                </a>
                                <div class="media-body">
                                    <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                                        <h4>{{ product?.title | titlecase }}</h4>
                                    </a>
                                    <h4>
                                <span>
                                {{ product?.quantity }} x
                                    {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                                </span>
                                    </h4>
                                </div>
                            </div>
                            <div class="close-circle">
                                <a href="javascript:void(0)" (click)="removeItem(product)">
                                    <i class="ti-trash" aria-hidden="true"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                    <ul class="cart_total">
                        <li>
                            <div class="total">
                                <h5>
                                    subtotal :
                                    <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span>
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div class="buttons">
                                <a [routerLink]="['/shop/cart']" class="btn btn-solid btn-xs view-cart">view cart</a>
                                <a [routerLink]="['/shop/checkout']" class="btn btn-solid btn-xs checkout">checkout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-sm-12 empty-cart-cls text-start" *ngIf="!products.length">
            <h4 class="mt-3">
                <strong>Your Cart is Empty</strong>
            </h4>
            <a [routerLink]="['/shop/collection/left/sidebar']" class="btn btn-solid">continue shopping</a>
        </div>
    </div>
</div>