import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../modules/auth/services/auth.service';
import {ILoginResponse} from '../core/models/interface/ILoginResponse';
import {RegistrationModel} from '../modules/auth/models/registration.model';
import {LoginModel} from '../modules/auth/models/login.model';
import {StringOrUndefined, Credentials, InfoFieldErrorModel} from '../core';
import {ILabelValueRoute} from '../modules/profile/interfaces/TabSelectedCode';
import {SharedService} from '../shared/services/shared.service';
import {NavService} from '../shared/services/nav.service';
import {Root} from '../shared/models/MegaMenu';
import {Slider} from './home/models/slider.model';
import {Configuration} from './home/models/configuration.model';
import {HomeService} from './home/services/home.service';
import {User, UserInfo} from '../modules/auth/models/User';
import {Dropdown, ProfileService} from '../modules/profile/services/profile.service';
import {AccountData} from '../modules/profile/models/account-data';
import {UpdatePasswordModel} from '../modules/profile/models/update-password.model';
import {IForgotPassword} from '../modules/auth/models/interface/IForgotPassword';
import {GenericResponse} from '../modules/auth/models/generic.response';
import {AddressModel} from '../modules/profile/models/address.model';
import {CommunicationResponseModel} from '../modules/profile/models/communication-response.model';
import {CommunicationRequestModel} from '../modules/profile/models/communication-request.model';
import {CommunicationEventsModel} from '../modules/profile/models/communication-events.model';
import {ArchivedOrdersModels, OrderItem, OrdersModels, ProcessingInfo} from '../modules/profile/models/orders-models';
import {IPredictionResponse} from '../shared/models/interfaces/IPredictionResponse';
import {IPhotonAdditionalFilter} from '../shared/models/interfaces/IPhotonAdditionalFilter';
import {ItemStructure} from '../shared/dynamic-loader/models/item-structure.model';
import {DynamicLoaderService} from '../shared/dynamic-loader/services/dynamic-loader.service';
import {ISector} from '../modules/profile/interfaces/ISector';
import {ISocietyResp} from '../modules/profile/interfaces/ISocietyResp';
import {SharedFormValidationService} from '../shared/services/shared-form-validation.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IDropdownData} from '../modules/profile/interfaces/IDropdownData';
import {IPaginationParams} from '../shared/interfaces/IPaginationParams';
import {TrustpilotService} from '../shared/services/trustpilot.service';
import {TrustpilotProductReviewResponse} from '../shared/models/trustpilotProductReview.model';
import {IProductSku, IStars, ITrustpilotProductReviewsSummary} from '../shared/models/interfaces/ITrustpilot';
import {FileUploaded} from '../shared/models/file-uploaded.model';
import {UploadFiles} from '../shared/models/upload-files.model';
import {ICashback} from '../modules/profile/interfaces/ICashback';
import {EstimateModel} from './estimate/models/estimate.model';
import {ContactUsModel} from '../shared/models/contact-us.model';
import {InfoFileUploadModel} from '../modules/profile/models/info-file-upload.model';
import {AzureRequest} from '../modules/profile/models/azure-request.model';
import {UploadingOrderFileModel} from '../shared/models/uploading-order-file-model';
import {Dispute, DisputeResponse, Ground, Solution} from '../modules/profile/models/dispute.model';
import {AttributesTechnicalProduct} from '../shared/dynamic-loader/models/attributes-technical-product.model';
import {ProcessingOrderCart} from '../shop/cart/models/processing-order-cart';
import {AttributesModel, Data} from '../shared/models/data.model';
import {BlogArticle} from '../shared/models/blog-article.model';
import {Faq, FaqCategory} from '../shared/models/faqs.model';
import {FaqSectionService} from './faq/services/faq-section.service';
import {BlogService} from './blog/services/blog.service';
import {EstimateCost} from '../shared/dynamic-loader/models/estimate-cost.model';
import {Price} from '../shared/dynamic-loader/models/price.model';
import {EyeletesRequest} from '../shared/dynamic-loader/models/eyeletes-request.model';
import {ResponseModel} from '../shared/dynamic-loader/models/response-model.model';

@Injectable({
    providedIn: 'root'
})
export class PagesFacade {
    constructor(private _authService: AuthService,
                private _sharedService: SharedService,
                private _faqSectionService: FaqSectionService,
                private _blogService: BlogService,
                private _homeService: HomeService,
                private _navService: NavService,
                private _profileService: ProfileService,
                private _dynamicLoader: DynamicLoaderService,
                private _trustpilotService: TrustpilotService,
                private sharedFormValidationServiceService: SharedFormValidationService) {
    }

    // Auth Service


    public getJwtToken(): string {
        return this._authService.getJwtToken();
    }

    public registration(registrationRequest: RegistrationModel): Observable<void> {
        return this._authService.registration(registrationRequest);
    }

    public login(userCredential: Credentials): Observable<ILoginResponse> {
        return this._authService.login(userCredential);
    }

    public updatePassword(filed: UpdatePasswordModel): Observable<User> {
        return this._authService.updatePassword(filed);
    }

    public createNewPassword(filed: UpdatePasswordModel): Observable<User> {
        return this._authService.createNewPassword(filed);
    }

    public logout() {
        return this._authService.logout();
    }

    public checkRegistrationFields(info: RegistrationModel): Array<InfoFieldErrorModel> {
        return this._authService.checkRegistrationFields(info);
    }

    public checkLoginFields(info: LoginModel): Array<InfoFieldErrorModel> {
        return this._authService.checkLoginFields(info);
    }

    public checkValidationManage(info: AccountData): Array<InfoFieldErrorModel> {
        return this._authService.checkValidationManage(info);
    }

    public checkBillingAddressInfo(info: AddressModel): Array<InfoFieldErrorModel> {
        return this._authService.checkBillingAddressInfo(info);
    }

    public checkAccountDataFields(info: AccountData): Array<InfoFieldErrorModel> {
        return this._authService.checkAccountDataFields(info);
    }

    public checkEstimateFields(info: EstimateModel): Array<InfoFieldErrorModel> {
        return this._authService.checkValidationOnEstimateForm(info);
    }

    public checkContactUs(info: ContactUsModel): Array<InfoFieldErrorModel> {
        return this._authService.checkContactUs(info);
    }

    public checkUpdatePasswordFields(info: UpdatePasswordModel): Array<InfoFieldErrorModel> {
        return this._authService.checkUpdatePasswordFields(info);
    }

    public checkCreatePasswordFields(info: UpdatePasswordModel): Array<InfoFieldErrorModel> {
        return this._authService.checkCreatePasswordFields(info);
    }

    public checkNotification(info: CommunicationResponseModel): Array<InfoFieldErrorModel> {
        return this._authService.checkNewNotificationFields(info);
    }

    public confirmAccount(token: string): Observable<GenericResponse> {
        return this._authService.confirmAccount(token);
    }

    public forgotPassword(email: string): Observable<IForgotPassword> {
        return this._authService.forgotPassword(email);
    }

    public checkForgotPasswordFields(info: StringOrUndefined): Array<InfoFieldErrorModel> {
        return this._authService.checkForgotPasswordFields(info);
    }

    public getEmailFromJwt(): string {
        return this._authService.getEmailFromJwt();
    }

    public clearLocalStorage(): void {
        this._authService.clearLocalStorage();
    }

    public getUser(): Observable<User> {
        return this._authService.getUserByToken();
    }

    public getUser$(): Observable<User> {
        return this._authService.getUser$();
    }

    public userValue(): User {
        return this._authService.userValue();
    }

    public setUserValue(value: User): void {
        this._authService.setUserValue$(value);
    }

    // Shared service...

    public getTabProfileSelected$(): Observable<ILabelValueRoute> {
        return this._sharedService.getTabProfileSelected$();
    }

    public setTabProfileSelected$(value: ILabelValueRoute): void {
        return this._sharedService.setTabProfileSelected$(value);
    }

    public getSearchTerms$(): Observable<string> {
        return this._sharedService.getSearchTerms$();
    }

    public setSearchTerms$(terms: string): void {
        return this._sharedService.setSearchTerms$(terms);
    }

    public getCartItems$(): Observable<Array<ProcessingOrderCart>> {
        return this._sharedService.getCartItems$();
    }

    public setCartItems$(value?: Array<ProcessingOrderCart>, singleElement?: ProcessingOrderCart): void {
        return this._sharedService.setCartItems$(value, singleElement);
    }

    public getInitialPhotonValues(): Observable<IPredictionResponse> {
        return this._sharedService.getInitialPhotonValues();
    }

    public getPredictionByFilters(searchParams: string | undefined, additionalQueryParams: string, otherFilters?: IPhotonAdditionalFilter): Observable<IPredictionResponse> {
        return this._sharedService.getPredictionByFilters(searchParams, additionalQueryParams, otherFilters);
    }

    public getByDynamicUrl<T>(url: string): Observable<T> {
        return this._sharedService.getByDynamicUrl<T>(url);
    }

    public uploadFile(files: UploadFiles): Observable<FileUploaded> {
        return this._sharedService.uploadFile(files);
    }

    public sendMailDesignYourIdea(contact: ContactUsModel): Observable<any> {
        return this._sharedService.sendMailDesignYourIdea(contact);
    }

    public getCaptcha(): Observable<any> {
        return this._sharedService.getCaptcha();
    }

    // Blog section...

    public getBlogList(pageSize: number, currentPage: number): Observable<Data<AttributesModel<BlogArticle>>> {
        return this._blogService.getBlogList(pageSize, currentPage);
    }

    public getBlogDetail(idBlogArticle: string): Observable<Data<AttributesModel<BlogArticle>>> {
        return this._blogService.getBlogDetail(idBlogArticle);
    }

    // Faq section...

    public getFAQ(): Observable<Data<AttributesModel<Faq>>> {
        return this._faqSectionService.getFAQ();
    }

    public getFAQCategories(): Observable<Data<AttributesModel<FaqCategory>>> {
        return this._faqSectionService.getFAQCategories();
    }

    // Home service...

    public getSliders(): Observable<Slider[]> {
        return this._homeService.getSliders();
    }

    public getTabDescriptionHome(): Observable<Configuration[]> {
        return this._homeService.getTabDescriptionHome();
    }

    public getBestSellerHead(): Observable<Configuration[]> {
        return this._homeService.getBestSellerHead();
    }

    public getSellerItems(): Observable<Configuration[]> {
        return this._homeService.getSellerItems();
    }

    public getDiscoverPromotionHead(): Observable<Configuration[]> {
        return this._homeService.getDiscoverPromotionHead();
    }

    public getDiscoverPromotionItems(): Observable<Configuration[]> {
        return this._homeService.getDiscoverPromotionItems();
    }

    public getSearchedItems(searchParams: IPaginationParams): Observable<HttpResponse<Array<ItemStructure>>> {
        return this._homeService.getSearchedItems(searchParams);
    }

    // Nav service ...
    public getMenu(): Observable<Root> {
        return this._navService.getMenu();
    }

    // profile service

    public updateUserInfo(userInfo: UserInfo): Observable<UserInfo> {
        return this._profileService.updateUserInfo(userInfo);
    }

    public getUserAddresses(): Observable<Array<AddressModel>> {
        return this._profileService.getUserAddresses();
    }

    public getAddressesById(id: string): Observable<AddressModel> {
        return this._profileService.getAddressesById(id);
    }

    public getUserCommunication(): Observable<Array<CommunicationResponseModel>> {
        return this._profileService.getUserCommunication();
    }

    public getUserCommunicationEvent(): Observable<Array<CommunicationEventsModel>> {
        return this._profileService.getUserCommunicationEvent();
    }

    public createNewCommunication(data: CommunicationRequestModel): Observable<CommunicationResponseModel> {
        return this._profileService.createNewCommunication(data);
    }

    public deleteCommunication(data: CommunicationRequestModel): Observable<GenericResponse> {
        return this._profileService.deleteCommunication(data);
    }

    public getOrders(page: number, pageSize: number, filter: string): Observable<OrdersModels> {
        return this._profileService.getOrders(page, pageSize, filter);
    }

    public getArchivedOrders(page?: number, pageSize?: number, filter?: string): Observable<ArchivedOrdersModels> {
        return this._profileService.getArchivedOrders(page, pageSize, filter);
    }

    public getOrderById(orderId: number): Observable<Array<OrderItem>> {
        return this._profileService.getOrderById(orderId);
    }

    public getOrderItemById(orderItemId: number): Observable<ProcessingInfo> {
        return this._profileService.getOrderItemById(orderItemId);
    }

    public getInfoOnFileUpload(orderItemId: number): Observable<InfoFileUploadModel> {
        return this._profileService.getInfoOnFileUpload(orderItemId);
    }

    public uploadFileAzure(files: UploadingOrderFileModel, azureRequest: AzureRequest): Observable<unknown> {
        return this._profileService.uploadFileAzure(files, azureRequest);
    }

    public getAzureSas(): Observable<InfoFileUploadModel> {
        return this._profileService.getAzureSas();
    }

    public confirmFileBookProof(orderItemId: number): Observable<GenericResponse> {
        return this._profileService.confirmFileBookProof(orderItemId);
    }

    public uploadNewFileBookProof(orderItemId: number): Observable<GenericResponse> {
        return this._profileService.uploadNewFileBookProof(orderItemId);
    }

    public cancelOrderBookProof(orderItemId: number): Observable<GenericResponse> {
        return this._profileService.cancelOrderBookProof(orderItemId);
    }

    public confirmFilePdf(orderItemId: number): Observable<GenericResponse> {
        return this._profileService.confirmFilePdf(orderItemId);
    }

    public uploadNewFilePdf(orderItemId: number): Observable<GenericResponse> {
        return this._profileService.uploadNewFilePdf(orderItemId);
    }

    public deleteAddress(id: number): Observable<GenericResponse> {
        return this._profileService.deleteAddress(id);
    }

    public saveAddress(address: AddressModel): Observable<AddressModel> {
        return this._profileService.saveAddress(address);
    }

    public updateAddress(address: AddressModel): Observable<AddressModel> {
        return this._profileService.updateAddress(address);
    }

    public getSector(): Observable<ISector> {
        return this._profileService.getSector();
    }

    public getSociety(): Observable<ISocietyResp> {
        return this._profileService.getSociety();
    }

    public getHowKnowUs(): Observable<IDropdownData> {
        return this._profileService.getHowKnowUs();
    }

    public getCountry(): Observable<IDropdownData> {
        return this._profileService.getCountry();
    }

    public getProvince(): Observable<IDropdownData> {
        return this._profileService.getProvince();
    }

    public getProvinceShared(): Observable<Array<Dropdown>> {
        return this._profileService.getProvinceShared();
    }

    public getCapByStateCode(code: string): Observable<IDropdownData> {
        return this._profileService.getCapByStateCode(code);
    }

    public getLocationByCap(code: string): Observable<IDropdownData> {
        return this._profileService.getLocationByCap(code);
    }

    public getAllDispute(page: number, pageSize: number, filter: string): Observable<DisputeResponse> {
        return this._profileService.getAllDispute(page, pageSize, filter);
    }

    public getDisputeById(id: number): Observable<Dispute> {
        return this._profileService.getDisputeById(id);
    }

    public getDisputeGrounds(): Observable<Array<Ground>> {
        return this._profileService.getDisputeGrounds();
    }

    public getSolutions(): Observable<Array<Solution>> {
        return this._profileService.getSolutions();
    }

    public checkIfAnOrderItemCanBeDisputable(orderItemId: number): Observable<GenericResponse> {
        return this._profileService.checkIfAnOrderItemCanBeDisputable(orderItemId);
    }

    public isButtonChanged(): Observable<boolean> {
        return this._profileService.isButtonChanged();
    }

    public getNextButtonValue(): boolean {
        return this._profileService.getNextButtonValue();
    }

    public setNextButton(value: boolean): void {
        this._profileService.setNextButton(value);
    }

    public isSharedOrderDataChanged(): Observable<ProcessingInfo> {
        return this._profileService.isSharedOrderDataChanged();
    }

    public getSharedOrderData(): ProcessingInfo {
        return this._profileService.getSharedOrderData();
    }

    public setSharedOrderData(value: ProcessingInfo): void {
        this._profileService.setSharedOrderData(value);
    }

    public getCashbackData(): Observable<ICashback> {
        return this._profileService.getCashbackData();
    }

    public getOrderItemNumber(): number {
        return this._profileService.getOrderItemNumber();
    }

    public setOrderItemNumber(value: number) {
        this._profileService.setOrderItemNumber(value);
    }

    //  dynamic loader

    public getRelatedProduct(terms: { terms: string[]; }): Observable<Array<ItemStructure>> {
        return this._dynamicLoader.getRelatedProduct(terms);
    }

    public getItemStructureById(id: string): Observable<ItemStructure> {
        return this._dynamicLoader.getItemStructureById(id);
    }

    public getTechnicalProducts(technicalProductKey: string): Observable<AttributesTechnicalProduct> {
        return this._dynamicLoader.getTechnicalProducts(technicalProductKey);
    }

    public getGetEyeletsTechnicalProducts(eyelets: EyeletesRequest): Observable<ResponseModel> {
        return this._dynamicLoader.getGetEyeletsTechnicalProducts(eyelets);
    }

    public estimateCost(productEstimateCost: EstimateCost): Observable<Price> {
        return this._dynamicLoader.estimateCost(productEstimateCost);
    }

    //  sharedFormValidationService

    public validForm<T>(obj: T, callback: (obj: T) => Array<InfoFieldErrorModel>): boolean {
        return this.sharedFormValidationServiceService.validForm(obj, callback);
    }

    public getFirstErrorByField(field: string): string {
        return this.sharedFormValidationServiceService.getFirstErrorByField(field);
    }

    public resetFieldValidation(field: string) {
        this.sharedFormValidationServiceService.resetFieldValidation(field);
    }

    public resetAllFieldValidation() {
        this.sharedFormValidationServiceService.resetAllFieldValidation();
    }

    public isEmptyErrorByField(field: string): boolean {
        return this.sharedFormValidationServiceService.isEmptyErrorByField(field);
    }

    public managementErrorFields(error: HttpErrorResponse): void {
        this.sharedFormValidationServiceService.managementErrorFields(error);
    }

    public getCartItem(): Observable<Array<ProcessingOrderCart>> {
        return this._sharedService.getCartItem();
    }

    // trustpilot

    public getTrustpilotReviews(paginationParams: IPaginationParams, sku: Array<string>): Observable<TrustpilotProductReviewResponse> {
        return this._trustpilotService.getTrustpilotReviews(paginationParams, sku);
    }

    public getTrustpilotProductReviewsSummary(sku: Array<string>): Observable<ITrustpilotProductReviewsSummary> {
        return this._trustpilotService.getTrustpilotProductReviewsSummary(sku);
    }

    public getTrustpilotStarImageResources(stars: number): Observable<IStars> {
        return this._trustpilotService.getTrustpilotStarImageResources(stars);
    }

    public skuList(): Observable<Array<IProductSku>> {
        return this._trustpilotService.skuList();
    }
}

