// Angular
import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
// RxJS
import {BehaviorSubject, EMPTY, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../modules/auth/services/auth.service';
import {ILoginResponse} from '../../models/interface/ILoginResponse';
import {environment} from '../../../../environments/environment';
import {IS_STRAPI_API} from '../../enums/public-api.token';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private router: Router,
                private authService: AuthService,
                private modalService: NgbModal) {
    }

    // intercept request and add token
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addCustomHeaders(request);

        if (request.context.get(IS_STRAPI_API)) {
            request = this.addToken(request, environment.jwtStrapiPressup);
        } else {
            if (this.authService.getJwtToken()) {
                request = this.addToken(request, this.authService.getJwtToken() as string);
            }
        }
        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error?.url?.includes('/User/Refresh')) {
                    this.modalService.dismissAll();
                    this.isRefreshing = false;
                    this.authService.clearLocalStorage();
                    this.router.navigateByUrl('/auth/login?sessionExpired=true');
                    return throwError(() => error);
                }
                if (error instanceof HttpErrorResponse && error.status === 401 && !error?.url?.includes('/User/Refresh') && !error?.url?.includes('/User/Login')) {
                    return this.handle401Error(request, next);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    private addCustomHeaders(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'X-API-KEY': environment?.xApiKey,
                'request-locale': 'it',
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(
                switchMap((loginResponse: ILoginResponse) => {
                    this.isRefreshing = false;
                    if (loginResponse) {
                        this.refreshTokenSubject.next(loginResponse?.token);
                        return next.handle(this.addToken(request, loginResponse?.token));
                    } else {
                        return EMPTY;
                    }
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(jwt => next.handle(this.addToken(request, jwt)))
            );
        }
    }
}
