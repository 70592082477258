import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {ItemStructure} from '../models/item-structure.model';
import {AttributesTechnicalProduct} from '../models/attributes-technical-product.model';
import {EstimateCost} from '../models/estimate-cost.model';
import {Price} from '../models/price.model';
import {EyeletesRequest} from '../models/eyeletes-request.model';
import {ResponseModel} from '../models/response-model.model';

@Injectable({
    providedIn: 'root'
})
export class DynamicLoaderService {

    private readonly ENDPOINT_V2: string = `${environment.addressPressupBackofficeAPI}${environment.api_v2}`;
    private readonly ENDPOINT_PRESSUP: string = `${environment.addressPressupAPIDEM}${environment.api_v1}`;

    constructor(private http: HttpClient) {
    }

    public getItemStructureById(id: string): Observable<ItemStructure> {
        return this.http.get<ItemStructure>(`${this.ENDPOINT_V2}/menu-item/${id}`);
    }

    public getGenericItemByUri(uri: string): Observable<ItemStructure> {
        return this.http.get<ItemStructure>(`${this.ENDPOINT_V2}/menu-item/uri?uri=${uri}`);
    }

    public getTechnicalProducts(technicalProductKey: string): Observable<AttributesTechnicalProduct> {
        return this.http.get<AttributesTechnicalProduct>(`${this.ENDPOINT_PRESSUP}/Product/Get?key=${technicalProductKey}`);
    }

    public getGetEyeletsTechnicalProducts(eyelets: EyeletesRequest): Observable<ResponseModel> {
        return this.http.get<ResponseModel>(`${this.ENDPOINT_PRESSUP}/Product/GetEyelets/${eyelets?.accessor}/${eyelets?.verticalFormat}/${eyelets?.horizontalFormat}/?_FormatoOriz=${eyelets?.horizontalFormat}`);
    }

    public estimateCost(productEstimateCost: EstimateCost): Observable<Price> {
        return this.http.post<Price>(`${this.ENDPOINT_PRESSUP}/Product/EstimateCost`, productEstimateCost);
    }

    public getRelatedProduct(terms: { terms: string[]; }): Observable<Array<ItemStructure>> {
        return this.http.post<Array<ItemStructure>>(`${this.ENDPOINT_V2}/product/related-products-by-criteria`, terms);
    }
}
