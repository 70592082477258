import {Injectable} from '@angular/core';
import {HttpClient, HttpContext} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {AttributesModel, Data} from '../../../shared/models/data.model';
import {BlogArticle} from '../../../shared/models/blog-article.model';
import {IS_STRAPI_API} from '../../../core/enums/public-api.token';

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    private readonly ENDPOINT_STRAPI: string = `${environment.addressPressupStrapi}`;

    constructor(private http: HttpClient) { }

    public getBlogList(pageSize: number, currentPage: number): Observable<Data<AttributesModel<BlogArticle>>> {
        return this.http.get<Data<AttributesModel<BlogArticle>>>(`${this.ENDPOINT_STRAPI}/api/blogs?populate=*&sort=createdAt:desc&pagination[pageSize]=${pageSize}&pagination[page]=${currentPage}`,
            {
                context: new HttpContext().set(IS_STRAPI_API, true),
            });
    }

    public getBlogDetail(idBlogArticle: string): Observable<Data<AttributesModel<BlogArticle>>> {
        return this.http.get<Data<AttributesModel<BlogArticle>>>(`${this.ENDPOINT_STRAPI}/api/blogs?populate=*&filters[id][$eq]=${idBlogArticle}`,
            {
                context: new HttpContext().set(IS_STRAPI_API, true),
            });
    }
}
